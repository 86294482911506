import React from "react"
import styled from "@emotion/styled"

import Card from "./Card"

const Container = styled.section`
  max-width: 130rem;
  margin: 0 auto;
`

const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 3rem;
  align-items: center;
  @media only screen and (max-width: 52em) {
    grid-template-columns: 1fr;
  }
`

const Wrapper = styled.div`
  padding: 5rem;
  @media only screen and (max-width: 32em) {
    padding: 3rem;
  }
`

const ContactBody = ({ children, form }) => (
  <Container>
    <Content>
      {children}
      <Wrapper>
        <Card>{form}</Card>
      </Wrapper>
    </Content>
  </Container>
)

export default ContactBody
