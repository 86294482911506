import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Header from "../components/Header"
import Breadcrumbs from "../components/Breadcrumbs"
import ContactBody from "../components/ContactBody"
import CtaHeading from "../components/CtaHeading"
import MarkdownBody from "../components/MarkdownBody"
import ContactForm from "../components/ContactForm"
import PostSubmit from "../components/PostSubmit"

export const ContactPostTemplate = ({
  title,
  subtitle,
  formTitle,
  formType,
  html,
  main,
  breadcrumbs,
}) => {
  const [sent, setSent] = useState({
    status: false,
    title: "Thanks for reaching out",
    message: "We'll get back to you as soon as we can!",
  })
  return (
    <>
      <Header title={title} subtitle={subtitle}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 31">
          <path d="M189.153 26.93c121.47 16.124 113.612-29.358 199.673-15.782 86.224 13.636 288.928-22.41 257.672-4.071-31.54 18.36-102.164 101.113-188.704 93.718-86.378-7.336-95.318-12.98-165.35-7.846-70.315 5.154-201.805 20.804-266.342-3.834-64.537-24.637-73.321-64.17-7.26-78.236 66.02-14.206 37.237-.054 170.311 16.05z" />
        </svg>
      </Header>
      <Breadcrumbs data={breadcrumbs} />
      <CtaHeading title={main.title} subtitle={main.subtitle} />
      <ContactBody
        form={
          sent.status ? (
            <PostSubmit title={sent.title}>{sent.message}</PostSubmit>
          ) : (
            <ContactForm
              setSent={setSent}
              sent={sent}
              title={formTitle}
              formType={formType}
            />
          )
        }
      >
        <MarkdownBody data={html} />
      </ContactBody>
    </>
  )
}

const ContactPost = ({
  data: {
    markdownRemark: {
      frontmatter: { title, subtitle, formTitle, formType, main },
      html,
    },
  },
  location,
}) => {
  const breadcrumbs = location.pathname.split("/")
  const seoDesc = `ReCollect picks up your cans and electronics, and direct deposits you the refund. ${title}`
  return (
    <Layout
      title={`${title} | ReCollect | Book A Pickup Today`}
      seoDesc={seoDesc}
    >
      <ContactPostTemplate
        title={title}
        subtitle={subtitle}
        formTitle={formTitle}
        formType={formType}
        html={html}
        main={main}
        breadcrumbs={breadcrumbs}
      />
    </Layout>
  )
}

export default ContactPost

export const PageQuery = graphql`
  query ContactPostById($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        subtitle
        formTitle
        formType
        main {
          title
          subtitle
        }
      }
      html
    }
  }
`
